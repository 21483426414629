import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import getMetatags from "../util/getMetatags"
import Seo from "../components/Seo"

function Legal({ data: { nodeLegal } }) {

  const { title, /*  body,changed, */ date, showCTA } = nodeLegal
  const pdf = nodeLegal?.relationships?.pdf?.localFile?.publicURL
  const seo = getMetatags(nodeLegal.metatag)
  /* const docs = [
    { uri: pdf }
  ]; */

  // console.log(title)
  //console.log(pdf)
  // console.log(nodeLegal)
  //.log(blueTitle)
  /*
  const [selected, setSelected] = useState(false)
  */

  return (
    <Layout showCta={showCTA} /* headerBg="bg-background-gray" dark */>
      <Seo {...seo} />
      <div className="  pt-[6.3rem]">
        <div className="bg-blue py-12 lg:h-[170px] lg:bg-[url('/background/mesh3.png')] bg-cover bg-top">
          <div className="container text-white ">
            <h1 className="text-3xl lg:text-5xl font-bold  mx-auto text-center  leading-normal" >{title}</h1>
            <div className="text-center mt-2 text-sm" >Last Updated:{" "}{date}</div>
          </div>
        </div>
      </div>
      <section className="mt-6 lg:mt-12">
        <div className="container">

          <div className="pb-32">
            <iframe title="pdf" src={pdf + '#toolbar=0&navpanes=0&scrollbar=0&view=fitH'} className="w-full h-[900px]  " />
          </div>

        </div>
      </section>

    </Layout>
  )
}


export default Legal


export const query = graphql`
  query legal($id: String!) {
    nodeLegal(id: { eq: $id }) {
      id
      title
      changed(formatString: "MMMM DD Y")
      date: field_date(formatString: "MMMM DD Y")
      showCTA: field_show_book_demo
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      relationships {

        pdf:field_pdf_file {
          localFile {
            url
            publicURL
          }
        }
      }
    }
  }
`
